import openPopupWindow from "./popup-window";

// Those callbacks should have been previously defined but if not we make
// sure they are defined now since we will need them from now on.
const setupInlineIdentityConnections = function() {
  if (!window.onIdentityConnected) {
    window.onIdentityConnected = (calendarId, identityId, reconnecting)=> {
      const showCalendarCol = $(".manage-identities.show-calendar-col").length > 0;
      const viewByCalendar = $(`#collapse-calendar-${calendarId}`).length > 0;
      if (!identityId) return;

      $.get(
        `/calendars/${calendarId}/identities/${identityId}/reload?reconnecting=${reconnecting}&show_calendar_col=${showCalendarCol}&view_by_calendar=${viewByCalendar}`
      ).fail(()=> {
        window.alert("An error occurred while refreshing your connection. Please refresh the page and try again.");
      });
    };
  }

  if (!window.onIdentityConnectionFailed) {
    window.onIdentityConnectionFailed = (message)=> {
      const alertElement =
        `<div class="flash">
          <div class="alert alert-error alert-dismissible">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
            <span class="alert-text">
              ${message}
            </span>
          </div>
        </div>`;

      $(".flash").replaceWith(alertElement);
      $("html, body").animate({ scrollTop: 0 }, "slow");
    };
  }
};

$(document).on('turbo:load', ()=> {
  // Establish producers for Broadcast Channel messages
  const inlineConnectionResultElement = $("#inline-connection-result");
  if (inlineConnectionResultElement.length) {
    const inlineConnectionError = inlineConnectionResultElement.data("inline-connection-error");
    const calendarId = inlineConnectionResultElement.data("calendar-id");
    const identityId = inlineConnectionResultElement.data("identity-id");
    const reconnecting = inlineConnectionResultElement.data("reconnecting");

    const identityConnectionChannel = new BroadcastChannel(`loomly-inline-identity-connections--calendar-${calendarId}`);
    let params = { calendarId, identityId, reconnecting };
    let type = "identity-connection-other";

    if (inlineConnectionError) {
      params["error"] = inlineConnectionError;
      type = "identity-connection-failure";
    } else {
      type = "identity-connection-success";
    }

    identityConnectionChannel.postMessage({ type, params });
    identityConnectionChannel.close();

    window.close();
  }

  // Establish consumers for Broadcast Channel messages
  const inlineCalendarIdentityScopes = $("[data-inline-identity-connection-calendar-id]");
  if (inlineCalendarIdentityScopes.length) {
    const identityConnectionChannels = [];

    const calendarIds = [...new Set(inlineCalendarIdentityScopes.map((_index, element) => $(element).data("inline-identity-connection-calendar-id")).get())];

    calendarIds.forEach(calendarId => {
      const calendarChannel = new BroadcastChannel(`loomly-inline-identity-connections--calendar-${calendarId}`);

      calendarChannel.onmessage = event => {
        setupInlineIdentityConnections();

        if (event.data.type === "identity-connection-failure") {
          window.onIdentityConnectionFailed(event.data.params.error);
        } else if (event.data.type === "identity-connection-success") {
          const { calendarId, identityId, reconnecting } = event.data.params;
          window.onIdentityConnected(calendarId, identityId, reconnecting);
        } else {
          window.location.reload();
        }
      }

      identityConnectionChannels.push(calendarChannel);
    })

    // Close all channels prior to navigating away to prevent memory leaks and duplicate channel instances
    $(document).one('turbo:before-visit', () => {
      identityConnectionChannels.forEach(channel => channel.close());
    });
  }

  // We don't want to run the code below when inside post builder due to
  // the window callbacks possibly overriding the ones from post builder for
  // inline identities connections.
  if ($(".manage-identities").length > 0) {
    setupInlineIdentityConnections();
  }
});

$(document).on(
  "click",
  ".btn-inline-social-connection",
  (e)=> {
    setupInlineIdentityConnections();

    openPopupWindow($(e.target).closest(".btn-inline-social-connection").data("url"));
  }
);
