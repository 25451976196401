$(document).on("click", "[data-pico-action]", e => {
  const target = $(e.currentTarget);
  const action = target.attr("data-pico-action");
  const info = target.attr("data-pico-info");
  let parsedInfo;

  try {
    parsedInfo = JSON.parse(info || "{}");
  } catch (e) {
    console.error(`Pico info on action '${action}' is invalid JSON: `, info);
    return;
  }

  window.trackPicoUserAction(action, parsedInfo);
});
