$(document).on("turbo:load", function() {

  if ((".js-public-pricing-tabs").length) {
    $(".toggle-option[data-show='yearly']").click(function() {
      if ($(this).hasClass("active")) return;

      $(".toggle-option[data-show='monthly']").removeClass("active");
      $(this).addClass("active");

      $(this).closest(".pricing-container").find(".price[data-show='monthly']").addClass("hidden");
      $(this).closest(".pricing-container").find(".price[data-show='yearly']").removeClass("hidden");
    });

    $(".toggle-option[data-show='monthly']").click(function() {
      if ($(this).hasClass("active")) return;

      $(this).addClass("active");
      $(".toggle-option[data-show='yearly']").removeClass("active");

      $(this).closest(".pricing-container").find(".price[data-show='yearly']").addClass("hidden");
      $(this).closest(".pricing-container").find(".price[data-show='monthly']").removeClass("hidden");
    });
  }

  if ($(".public-plan-comparison-table").length) {
    $(".public-plan-comparison-table .plan-card .title").click(function() {
      if (!$(".card-toggle:visible").length) return;

      $(this).closest(".plan-card").toggleClass("show-more");
    });

    var last_height = undefined;
    function sendHeight(height) {
       if (height === last_height) return;
       last_height = height;
       const pass_data = {
         'pricing_table_height': height
       };
       parent.postMessage(JSON.stringify(pass_data), "https://www.loomly.com");
     }
     const resizeObserver = new ResizeObserver(entries => {
       sendHeight(entries[0].target.scrollHeight);
     });
     resizeObserver.observe(document.querySelector(".pricing-container"));
  }

  if ($(".pricing-features-table").length) {
    $(".pricing-features-table .accordion .accordion-heading").click(function() {
      $(this).closest(".accordion").toggleClass("active");
    });

    var last_height = undefined;
    function sendHeight(height) {
       if (height === last_height) return;
       last_height = height;
       const pass_data = {
         'comparison_table_height': height
       };
       parent.postMessage(JSON.stringify(pass_data), "https://www.loomly.com");
     }
     const resizeObserver = new ResizeObserver(entries => {
       sendHeight(entries[0].target.scrollHeight);
     });
     resizeObserver.observe(document.querySelector(".pricing-features-table"));
  }

  if ((".js-toggle-quick-pricing").length) {
    $(".js-toggle-quick-pricing").click(function() {
      const yearly_toggle = $(".toggle-option-yearly");

      // Toggle the pill box month -> year selection
      yearly_toggle.toggleClass("selected");
      $(".toggle-option-monthly").toggleClass("selected");

      // Update SVGs
      $(".js-svg-path").toggleClass("svg-monthly-path");
      $(".js-svg-circle-focus").toggleClass("svg-price-circle-blob-monthly");
      $(".emphasis").toggleClass("monthly");
      $(".js-button-teams").toggleClass("monthly");

      // Hide and show annual totals
      $(".js-annual-total").toggle();

      // Update Pro or Focused Price option box
      if (yearly_toggle.hasClass('selected')) {
        $(".js-focus").addClass("focus-yearly").removeClass("focus-monthly");
        $(".fa-check").removeClass("monthly");
        $(".best-value").removeClass("monthly");
        $(".best-value > button").removeClass("monthly");
        $(".js-focus-column").removeClass("focus-monthly");
        $(".js-subheader-billing-period").text("billed yearly in USD");
        $("[data-show-when='yearly']").show();
        $("[data-show-when='monthly']").hide();

        $(".price-value-container .price-value").each(function(i, el){
          const price_value = $(el);
          price_value.text(price_value.data("yearly-price-per-month"));
        });

      } else {
        $(".js-focus").removeClass("focus-yearly").addClass("focus-monthly");
        $(".js-focus-column").addClass("focus-monthly");
        $(".fa-check").addClass("monthly");
        $(".best-value > button").addClass("monthly");
        $(".best-value").addClass("monthly");
        $(".js-subheader-billing-period").text("billed monthly in USD");
        $("[data-show-when='yearly']").hide();
        $("[data-show-when='monthly']").show();

        $(".price-value-container .price-value").each(function(i, el){
          const price_value = $(el);
          price_value.text(price_value.data("monthly-price-per-month"));
        });
      }
    });
  }
});
