$(document).on('turbo:load', function() {
  const modal = $("#new-user-acceptance-modal");

  if (modal.length) {
    const code = modal.attr("data-code");

    modal.modal({ show: true, backdrop: 'static', keyboard: false });

    window.trackPicoUserAction('terms_of_service_modal_displayed', { terms_of_service_version: code });

    modal.find(".accept-button").on("click", e => {
      e.preventDefault();

      $.ajax({
        url: "/user_acceptances",
        type: "POST",
        data: { code },
        success: data => {
          modal.on("hidden.bs.modal", () => modal.remove());
          modal.modal("hide");

          window.trackPicoUserAction('terms_of_service_modal_accepted', { terms_of_service_version: code });
        },
        error: (jqXHR) => {
          const errorMessage = (jqXHR.responseJSON?.errors && jqXHR.responseJSON?.errors.join(", ")) || jqXHR.responseText;
          alert(`An error occurred. Please try again. The response was: ${errorMessage}`);
        }
      });
    });
  }

  const flash = $("#new-user-acceptance-flash");

  if (flash.length) {
    const code = flash.attr("data-code");

    // TODO: window.trackPicoUserAction('price_change_flash_displayed', { price_change_version: code });

    flash.find(".accept-button").on("click", e => {
      $.ajax({
        url: "/user_acceptances",
        type: "POST",
        data: { code },
        success: data => {
          flash.hide();

          // TODO: window.trackPicoUserAction('price_change_flash_accepted', { price_change_version: code });
        },
        error: (jqXHR) => {
          const errorMessage = (jqXHR.responseJSON?.errors && jqXHR.responseJSON?.errors.join(", ")) || jqXHR.responseText;
          alert(`An error occurred. Please try again. The response was: ${errorMessage}`);
        }
      });
    });
  }
});
